import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import {
  Grid,
  TextField,
  Button,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import "./Flightform.css";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AllData from "../dashboard/data/AllData";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { BorderColor } from "@material-ui/icons";
import InputBase from "@mui/material/InputBase";
import FlyFrom from "../imagesmy/from.svg";
import FlyTo from "../imagesmy/to.svg";
import searchicon from "../imagesmy/searchicon.svg";
import selecticon from "../imagesmy/selectdrop.svg";
import flightloader from "../imagesmy/flightloader.gif";
import ChatComponent from "./ChatComponent";

// this section for button style
const useStyles = makeStyles({
  root: {
    position: "relative",
    overflow: "hidden",
  },

  btn: {
    backgroundColor: "#FC3C00 !important",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
    width: "218px",
    height: "65px",
    marginTop: 15,
    position: "relative",
    padding: "",
    borderRadius: "10px",
  },
});

const textStyles = makeStyles((theme) => ({
  customTextField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px", // Add your desired border radius here
      borderWidth: "2px", // Add your desired border width here
      backgroundColor: "#F0F0F0",
      BorderColor: "#D1D1D1",
    },
  },
}));

// this section for data of form

const flightb = ["No", "Yes"];

const flighte = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"];
const flightf = ["0", "01", "02", "03", "04", "05", "06", "07", "08"];
const flightg = ["0", "01", "02", "03", "04", "05", "06", "07", "08"];

function FlightForm() {
  const classess = textStyles();

  const navigate = useNavigate();
  const [minDate, setMinDate] = React.useState(new Date("mm/dd/yy"));

  // this section for date
  const [datevalue, setdate] = React.useState(new Date("mm/dd/yy"));
  const dateChange = (newValue) => {
    setdate(newValue);
    // setMinDate(newValue.setDate(newValue.getDate()+1));
  };

  const [datevalue2, setdate2] = React.useState(datevalue);
  const dateChange2 = (newValue2) => {
    setdate2(newValue2);
  };

  const originalDate = new Date(datevalue);
  const originalDate2 = new Date(datevalue2);

  const formattedDateFrom = `${originalDate.getDate()}-${
    originalDate.getMonth() + 1
  }-${originalDate.getFullYear()}`;
  const formattedDateTo = `${originalDate2.getDate()}-${
    originalDate2.getMonth() + 1
  }-${originalDate2.getFullYear()}`;

  // this section for Fight direct or indirect
  const [valueb, setValueb] = React.useState(flightb[0]);
  const [inputValueb, setInputValueb] = React.useState("");

  //  this section for select flight
  //  const [valuec, setValuec] = React.useState(flightc[0]);
  //  const [inputValuec, setInputValuec] = React.useState('');

  //  this section for class flight
  //  const [valued, setValued] = React.useState(flightd[0]);
  //  const [inputValued, setInputValued] = React.useState('');

  //  this section for Adult
  const [selectedPassenger, setSelectedPassenger] = useState(0); // Default to Adult
  const [adult, setAdult] = useState(1); // Default to 1 Adult traveler
  const [child, setChild] = useState(0); // Default to 1 Child traveler
  const [infant, setInfant] = useState(0); // Default to 1 Infant traveler

  const handleCounterChange = (category, value) => {
    if (category === "adult") {
      setAdult((prevAdult) => Math.max(prevAdult + value, 0));
    } else if (category === "child") {
      setChild((prevChild) => Math.max(prevChild + value, 0));
    } else if (category === "infant") {
      setInfant((prevInfant) => Math.max(prevInfant + value, 0));
    }
    console.log(adult, child, infant);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  //this section for geting all data of flights

  const [flightsdata, setflightsData] = useState([]);
  const [flyingFrom, setFlyingFrom] = React.useState("");
  // const [inputValue, setInputValue] = React.useState('');
  const [flyingTo, setFlyingTo] = React.useState("");
  //* this is for  pop up
  const [popupData, setPopupData] = useState(null); // To store the 'show' value
  const [popupId, setPopupId] = useState(null); // To store the document ID

  useEffect(() => {
    fetchPopupData();
  }, []);
  console.log("this is popup value: in flightform,", popupData);
  const fetchPopupData = async () => {
    try {
      const querySnapshot = await AllData.getPopupValue();
      console.log("QuerySnapshot:", querySnapshot);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        console.log("Document ID:", doc.id);
        console.log("Document Data:", doc.data());

        const docData = doc.data();
        if (docData && typeof docData.show === "boolean") {
          // Use 'Show' with a capital S
          setPopupData(docData.show); // Update to match the field name
          setPopupId(doc.id);
        } else {
          console.warn("The 'Show' field is missing or not a boolean.");
        }
      } else {
        console.warn("No documents found in the 'popUp' collection.");
      }
    } catch (err) {
      console.error("Error fetching popup data:", err);
    }
  };
  // console.log("flyingfrom:",datevalue)
  // console.log("inputvalue:",datevalue2)

  useEffect(() => {
    getFlight();
  }, []);

  const getFlight = async () => {
    const data = await AllData.getAllFlights();

    setflightsData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  //this section for distinations

  const [destidata, setdestiData] = useState([]);

  useEffect(() => {
    getDesti();
  }, []);

  const getDesti = async () => {
    const data = await AllData.getAllDesti();

    setdestiData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  //this section for getting data of airlines

  const [airlinesdata, setairlinesData] = useState([]);

  useEffect(() => {
    getAirline();
  }, []);

  const getAirline = async () => {
    const data = await AllData.getAllAirlines();

    setairlinesData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  //this section for class of flights
  const [classesdata, setclassesData] = useState([]);

  useEffect(() => {
    getClass();
  }, []);

  const getClass = async () => {
    const data = await AllData.getAllClasses();

    setclassesData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  //this section for useStyle

  const classes = useStyles();

  //this section for second form
  const [open, setOpen] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [flightCards, setFlightCardsOpen] = React.useState(false);
  const [IssuccessMsg, setSuccessMsg] = useState(false);

  // const handleModalOpen = () =>{
  //   setOpenBackdrop(true)

  //   setTimeout(() => {
  //     setOpenBackdrop(false)
  //     if (flightCards == false && flightsdata.length !== 0) {
  //       navigate('/book-flight', {
  //         state: {
  //           From: flyingFrom,
  //           To: flyingTo,
  //           flightsdata: flightsdata,
  //           setOpen: open,
  //           Adult: adult,
  //           Child: child,
  //           Infant: infant,
  //           Fromdate: datevalue,
  //           Todate: datevalue2,
  //         },
  //       });
  //     } else {
  //       setFlightCardsOpen(true)
  //     }

  //   }, 2000)

  // }

  // const handleClickSecondOpen = (e) => {

  //   e.preventDefault();
  //   setFlightCardsOpen(false)
  //   setOpen(true)

  // }
  // this section for checking popup
  const handleClickOpen = (e) => {
    e.preventDefault();

    const updatedData = flightsdata.filter((curData) => {
      return curData.origin == flyingFrom && curData.desti == flyingTo;
    });

    setOpenBackdrop(true);
    setTimeout(() => {
      setOpenBackdrop(false);
      setflightsData(updatedData);
      setFlightCardsOpen(true);
      //***********This section for skip router start */
      if (popupData == false) {
        console.log("this is popup fasle", popupData);
        if (updatedData.length !== 0) {
          console.log("this is show false ");
          navigate("/book-flight", {
            state: {
              From: flyingFrom,
              To: flyingTo,
              flightsdata: flightsdata,
              setOpen: open,
              Adult: adult,
              Child: child,
              Infant: infant,
              Fromdate: datevalue,
              Todate: datevalue2,
            },
          });
        }
      }
      // else {
      //   setFlightCardsOpen(true);
      // }
      // setFlightCardsOpen(true);

      //***********This section for skip router end */
    }, 2000);
  };

  const handleClose = () => {
    setOpen(false);
    setFlightCardsOpen(false);
    setFlyingFrom("");
    setFlyingTo("");
    setflightsData("");
    window.location.reload();
  };
  //this section for  email sending

  const form = useRef();
  const sendEmail = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!form.current.reportValidity()) {
      return;
    }

    // console.log("form.current", form.current);
    //********this is unskip  */
    if (popupData == true) {
      if (flightsdata.length !== 0) {
        navigate("/book-flight", {
          state: {
            From: flyingFrom,
            To: flyingTo,
            flightsdata: flightsdata,
            setOpen: open,
            Adult: adult,
            Child: child,
            Infant: infant,
            Fromdate: datevalue,
            Todate: datevalue2,
          },
        });
      } else {
        navigate("/", {
          state: { From: flyingFrom, To: flyingTo, flightsdata: flightsdata },
        });
      }
    }

    try {
      await emailjs.sendForm(
        "service_b78n6te",
        "template_r0p8nyn",
        form.current,
        "U9N4oeLD7PnGGl6QO"
      );

      setSuccessMsg(true);
      setFlightCardsOpen(false);
      console.log("mails sent");

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  // this section for new textfield first
  const onChange = (event) => {
    setFlyingFrom(event.target.value);
  };

  const onSearch = (searchTerm) => {
    setFlyingFrom(searchTerm);
  };
  // this section for new textfield second
  const onChange1 = (event) => {
    setFlyingTo(event.target.value);
  };

  const onSearch1 = (searchTerm) => {
    setFlyingTo(searchTerm);
    // our api to fetch the search result
  };

  return (
    //form
    <>
      <form ref={form} style={{ zIndex: 9999999 }}>
        <React.Fragment>
          {/* <h3 className='form-heading'> Book Flight</h3> */}

          {/* container */}
          <div className="flightCardsContainer">
            <Grid container spacing={2} alignItems="center">
              {/* first text field */}
              <Grid item xs={12} sm={3} lg={3}>
                <div className="grey-field botharrow">
                  <div>
                    <img src={FlyFrom} alt="" />
                    <span>From</span>
                  </div>

                  <InputBase
                    className={classes.customInputBase}
                    autoComplete="off"
                    fullWidth
                    onChange={onChange}
                    placeholder="City, Airport, Country"
                    value={flyingFrom}
                    name="flying_from"
                  />
                </div>

                <div className="dropdown">
                  {destidata
                    .filter((item) => {
                      const searchTerm = flyingFrom.toLowerCase();
                      const fullName = item.fullplace.toLowerCase();
                      //  console.log('data',fullName);

                      return (
                        searchTerm &&
                        fullName.includes(searchTerm) &&
                        fullName !== searchTerm
                      );
                    })
                    .slice(0, 10)
                    .map((item) => (
                      <div
                        onClick={() => onSearch(item.fullplace)}
                        className="dropdown-row"
                        key={item.fullplace}
                      >
                        {item.fullplace}
                      </div>
                    ))}
                </div>
              </Grid>

              {/* second text field */}

              <Grid item xs={12} sm={3} lg={3}>
                <div className="grey-field">
                  <div>
                    <img src={FlyTo} alt="" />
                    <span>To</span>
                  </div>

                  <InputBase
                    autoComplete="off"
                    fullWidth
                    onChange={onChange1}
                    placeholder="City, Airport, Country"
                    value={flyingTo}
                    name="flying_to"
                    variant="outlined"
                  />
                </div>

                <div className="dropdown">
                  {destidata
                    .filter((item) => {
                      const searchTerm = flyingTo.toLowerCase();
                      const fullName = item.fullplace.toLowerCase();
                      //  console.log('data',fullName);

                      return (
                        searchTerm &&
                        fullName.includes(searchTerm) &&
                        fullName !== searchTerm
                      );
                    })
                    .slice(0, 10)

                    .map((item) => (
                      <div
                        onClick={() => onSearch1(item.fullplace)}
                        className="dropdown-row"
                        key={item.fullplace}
                      >
                        {item.fullplace}
                      </div>
                    ))}
                </div>
              </Grid>

              {/* Departure select date */}
              <Grid className="pr-0" item xs={12} sm={3} lg={2}>
                <div className="grey-field rounded-right">
                  <div>
                    <img src={FlyFrom} alt="" />
                    <span>Depart</span>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                      <MobileDatePicker
                        minDate={new Date()}
                        label="mm/dd/yyyy"
                        // inputFormat="MM/dd/yyyy"
                        value={datevalue}
                        onChange={dateChange}
                        renderInput={(params) => (
                          <InputBase
                            {...params}
                            name="departure_date"
                            variant="outlined"
                            placeholder="mm/dd//yy"
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </Grid>
              {/* Returning select date */}
              <Grid className="pl-0" item xs={12} sm={3} lg={2}>
                {/* <label>
                  Returning
                </label> */}

                <div className="grey-field rounded-left">
                  <div>
                    <img src={FlyFrom} alt="" />
                    <span>Return</span>
                  </div>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                      <MobileDatePicker
                        minDate={datevalue}
                        label="mm/dd/yyyy"
                        value={datevalue2}
                        onChange={dateChange2}
                        renderInput={(params) => (
                          <InputBase
                            {...params}
                            name="returning_date"
                            variant="outlined"
                            placeholder="dd/mm/yy"
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </Grid>

              {/* Select Adult child adult infant */}

              <Grid item xs={12} sm={6} lg={2}>
                <div className="grey-fieldtravel rounded">
                  <div>
                    <span>Travellers</span>
                  </div>

                  <FormControl className="adult-select ">
                    <Select
                      fullWidth
                      className="select-person "
                      value={selectedPassenger}
                      onChange={(e) => setSelectedPassenger(e.target.value)}
                    >
                      <MenuItem value={0} onClick={stopPropagation}>
                        <div className="flex justify-between select-font">
                          Adult
                          <div className="adut-increment">
                            <Button
                              className="incre-decre "
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCounterChange("adult", -1);
                              }}
                            >
                              -
                            </Button>
                            <span className="adults-no">{adult}</span>

                            <Button
                              className="incre-decre"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCounterChange("adult", 1);
                              }}
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      </MenuItem>
                      <MenuItem value={1} onClick={stopPropagation}>
                        <div className="flex justify-between select-font">
                          Child
                          <div className="adut-increment">
                            <Button
                              className="incre-decre"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCounterChange("child", -1);
                              }}
                            >
                              -
                            </Button>
                            <span className="adults-no">{child}</span>
                            <Button
                              className="incre-decre"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCounterChange("child", 1);
                              }}
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      </MenuItem>
                      <MenuItem value={2} onClick={stopPropagation}>
                        <div className="flex justify-between select-font">
                          Infant
                          <div className="adut-increment">
                            <Button
                              className="incre-decre"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCounterChange("infant", -1);
                              }}
                            >
                              -
                            </Button>
                            <span className="adults-no">{infant}</span>
                            <Button
                              className="incre-decre"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCounterChange("infant", 1);
                              }}
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      </MenuItem>
                      {/* <Button className='done-btn'> Done</Button> */}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              {/* Select Adult */}

              <Grid container justifyContent="center" alignItems="center">
                <Grid item lg={12}>
                  <div className="text-center">
                    <Button className={classes.btn} onClick={handleClickOpen}>
                      <img src={searchicon} alt="" /> Search Flight
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>

          {/* this section for second form */}

          {open && (
            <div className="modalBackground">
              <div className="modal-wrapper">
                <div className="modalContainer">
                  <div className="titleCloseBtn">
                    <button onClick={handleClose}>X</button>
                  </div>
                  <DialogTitle>Please Provide Data</DialogTitle>

                  <DialogContent>
                    <TextField
                      required
                      autoFocus
                      margin="dense"
                      name="c_name"
                      label="Full Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                    <TextField
                      margin="dense"
                      label="Email Address"
                      type="text"
                      name="c_email"
                      fullWidth
                      variant="outlined"
                    />
                    <TextField
                      required
                      margin="dense"
                      id="phone"
                      name="phone"
                      label="Contact NO"
                      type="tel" // Ensure the type is "tel" for phone numbers
                      fullWidth
                      variant="outlined"
                    />
                    <TextField
                      margin="dense"
                      name="c_message"
                      label="Message"
                      type="text"
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>

                    <button onClick={sendEmail}>
                      <Link
                        to="/book-flight"
                        state={{
                          From: flyingFrom,
                          To: flyingTo,
                          flightsdata: flightsdata,
                        }}
                      >
                        {" "}
                        send
                      </Link>{" "}
                    </button>
                  </DialogActions>
                </div>
              </div>
            </div>
          )}

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
          >
            {/* <CircularProgress color="inherit" /> */}
            <div className="loader-wrapper text-center">
              <img
                cl
                className="mx-auto"
                src={flightloader}
                height="300"
                width="300"
                alt="Loader"
              />
              <div className="text-wrapper">
                <h2>Searching for Cheap Flights</h2>
                <p>
                  You told us when and where... now let us take you there with
                  our great fares on over 500 airlines!
                </p>
              </div>
            </div>
          </Backdrop>

          {/* this section flightcards */}

          {/* {flightCards && flightsdata.length !== 0 &&

            navigate('/book-flight', { state: { From: flyingFrom, To: flyingTo, flightsdata: flightsdata, setOpen: open, Adult: adult, Child: child, Infant: infant, Fromdate: datevalue, Todate: datevalue2 } })



          }, */}

          {IssuccessMsg && (
            <div className="modalContainer">
              <div className="modal-content">
                <h4>
                  "Sorry, we couldn't find the flight you requested, but our
                  team has been notified and they will get back to you soon."{" "}
                </h4>
              </div>
            </div>
          )}

          {/* this section for popup */}

          {flightCards && (
            <div className="modalContainer">
              <div className="modal-content">
                <div className="titleCloseBtn">
                  <button onClick={handleClose}>X</button>
                </div>
                <DialogTitle>Please Enter your Data</DialogTitle>

                <input type="hidden" name="from" value={formattedDateFrom} />
                <input type="hidden" name="to" value={formattedDateTo} />
                <input type="hidden" name="child_price" value={child} />
                <input type="hidden" name="adult_price" value={adult} />
                <input type="hidden" name="infant_price" value={infant} />

                <DialogContent>
                  <TextField
                    required
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Full Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                  />
                  <TextField
                    margin="dense"
                    label="Email Address"
                    type="text"
                    name="user_email"
                    fullWidth
                    variant="outlined"
                  />
                  <TextField
                    required
                    margin="dense"
                    id="name"
                    name="phone"
                    label="Contact NO"
                    type="number"
                    fullWidth
                    variant="outlined"
                  />
                  <TextField
                    margin="dense"
                    name="message"
                    label="Message"
                    type="text"
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    required
                  />
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>

                  <Button
                    className="contact-btn"
                    type="submit"
                    value="Send"
                    onClick={sendEmail}
                    style={{
                      backgroundColor: "#4052F3",
                      borderRadius: "8px",
                      color: "white",
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </div>
            </div>
          )}
        </React.Fragment>
      </form>

      <ChatComponent />
    </>
  );
}
export default FlightForm;
