import React, { useContext, useState } from "react";
import "./App.css";
import Home from "./components/pages/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Services from "./components/pages/AboutUs";
import Products from "./components/pages/Flights";
import SignUp from "./components/pages/Holidays";
import ContactUs from "./components/pages/ContactUs";
import Africa from "./components/pages/Africa";
import BookFlight from "./components/pages/BookFlight";
import Asia from "./components/pages/Asia";
import Europe from "./components/pages/Europe";
import FarEast from "./components/pages/FarEast";
import MiddleEast from "./components/pages/MiddleEast";
import Admin from "./dashboard/Admin";
import Userlist from "./dashboard/pages/userlist/Userlist";
import Airline from "./dashboard/pages/airlines/Airlines";
import Locations from "./dashboard/pages/destination/Locations";
import Fclasses from "./dashboard/pages/classes/Fclasses";
import SeasionalFares from "./dashboard/pages/seasonalfares/SeasonalFares";
import { Terms } from "./components/pages/Terms";
import { PrivacyPolicy } from "./components/pages/PrivacyPolicy";
import Register from "./dashboard/pages/userlist/Register";
import Login from "./dashboard/pages/userlist/Login";
import AddFlight from "./dashboard/pages/addflight/AddFlight";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "./dashboard/pages/userlist/ProtectedRoute";
import ScrollToTop from "./components/ScrollToTop";
import CheckOut from "./components/pages/CheckOut";
import BlogDetails from "./components/BlogDetails";
import BlogThailand from "./components/BlogThailand";
import BlogDetaailsDubai from "./components/BlogDetaailsDubai";
import PopUp from "./dashboard/pages/popup/popUp";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about-us" element={<Services />} />
          <Route path="/flights" element={<Products />} />
          <Route path="/Holidays" element={<SignUp />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/africa" element={<Africa />} />
          <Route path="/book-flight" element={<BookFlight />} />
          <Route path="/europe" element={<Europe />} />
          <Route path="/asia" element={<Asia />} />
          <Route path="/far_east" element={<FarEast />} />
          <Route path="/middle_east" element={<MiddleEast />} />
          <Route path="/terms_conditions" element={<Terms />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/BlogDetails" element={<BlogDetails />} />
          <Route path="/BlogThailand" element={<BlogThailand />} />
          <Route path="/BlogDetaailsDubai" element={<BlogDetaailsDubai />} />

          {/* this section for admin panel */}

          <Route path="/login" element={<Login />} />

          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                {" "}
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user"
            element={
              <ProtectedRoute>
                <Userlist />
              </ProtectedRoute>
            }
          />
          <Route path="/airline" element={<Airline />} />
          <Route
            path="/locations"
            element={
              <ProtectedRoute>
                <Locations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addflight"
            element={
              <ProtectedRoute>
                <AddFlight />
              </ProtectedRoute>
            }
          />

          <Route
            path="/seasional_fares"
            element={
              <ProtectedRoute>
                <SeasionalFares />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pop_up"
            element={
              <ProtectedRoute>
                <PopUp />
              </ProtectedRoute>
            }
          />
          <Route
            path="/f_classes"
            element={
              <ProtectedRoute>
                <Fclasses />
              </ProtectedRoute>
            }
          />

          <Route
            path="/register"
            element={
              <ProtectedRoute>
                <Register />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
